
const gitInfo = {
  branch: "master",
  commit: "fa91e1a",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/fa91e1a40ac53801815a29c576133b3c84fc5109",
  date: "24/01/2025",
};

export default gitInfo;
  